import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {SocketService} from './socket.service';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AdminService {
  query='&where=code_entite|e|BGDIGIT,code_societe|e|DASHBOARD'
  constructor(private http: HttpClient) { }

  getRoles(pagination= '',query_ = ''){
    this.query='&where=code_entite|e|BGDIGIT,code_societe|e|DASHBOARD';
      this.query += query_;
    let uri = `${environment.apiDashboard}/dashboard/roles?page=${pagination}${this.query}`;
    return this.http.get<any>(uri);
  }
  getAction(pagination=''){

    const query = '&where=code_plateforme|e|DASHBOARD'
    return this.http.get<any>(`${environment.apiDashboard}/dashboard/actions?page=${pagination}${query}`)
  }

  addRole(data){
    return this.http.post<any>(`${environment.apiDashboard}/dashboard/roles`,data);
  }

  createUser(data){
    return this.http.post<any>(`${environment.apiDashboard}/dashboard/users`,data);
  }

  allUser(pagination) {
    return this.http.get<any>(`${environment.apiDashboard}/dashboard/users?page=${pagination}&where=code_client|e|BGDIGIT,code_societe|e|DASHBOARD`);
  }
  addPermission(data){
    console.log('per',data)
    return this.http.post<any>(`${environment.apiDashboard}/dashboard/action_roles`,data)
  }
  getPermission(role){
    const query = `where=role_id|e|${role}`
      return this.http.get<any>(`${environment.apiDashboard}/dashboard/action_roles?${query}`)
  }
  getRegion(){
   const query = '?child=departements,departements.communes';
    return this.http.get<any>(`${environment.apiDashboard}/dashboard/regions${query}`)
  }

}
